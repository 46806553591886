import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
    PageHeader,
    TechnologyPartners,
    BrandsWorkedWithUs
} from '@components';
import {
    BetterThanOther,
    MultiColumnList,
    ImplementedTechnology
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class OnDemand extends Component {
    render() {
        const onDemand = pageContent.subPage.onDemand;
        return (
            <Layout>
                <PageHeader image={onDemand.headerImage} />

                <Heading
                    title={onDemand.title}
                    overview={onDemand.overview}
                    noHeadingIcon={true} />

                <BetterThanOther content={onDemand.betterThanOther} />

                <ImplementedTechnology content={onDemand.implementedTechnology}/>

                <MultiColumnList content={onDemand.typesOfApps} />

                <MultiColumnList content={onDemand.onDemandServices} />

                <PageNavigation
                    prev={pageContent.navigation.prev}
                    next={pageContent.navigation.next} />

                <TechnologyPartners />

                <BrandsWorkedWithUs />

            </Layout>
        );
    }
}

export default OnDemand;
